import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { useSpring, animated } from "react-spring"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

const Card = styled.article`
  position: absolute;
  overflow: hidden;
  background-size: cover;
  height: 60vh;
  color: #ffffff;
  padding: 50px;
  width: 100%;
  @media (max-width: 1500px) {
    width: 90%;
  }
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-items: center;
  justify-items: center;
  grid-column-gap: 30px;
  margin: 100px auto;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transition: all 2s;
    z-index: -1;
    background-color: ${({ background }) => background};
    background-size: cover;
  }
  &.moveRight {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 2500px;
      transition: all 2s;
      z-index: -1;
      background-color: ${({ background }) => background};
      background-size: cover;
    }
  }
  &.moveLeft {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: -2500px;
      transition: all 2s;
      z-index: -1;
      background-size: cover;
      background-color: ${({ background }) => background};
    }
  }
  button {
    background: #ffffff;
    border: none;
    padding: 20px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
  .content {
    justify-self: self-start;
    align-self: self-start;
    p {
      background: #d3d3d3d1;
      padding: 20px;
      color: #000000;
    }
  }
`

const CardWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 80%;
  display: block;
  height: 800px;
  margin: 0 auto;
`

const SliderWrapper = styled.div`
  .slider {
    height: 1000px;
    overflow: hidden;
    width: 100%;
    @media (min-width: 768px) {
      margin: 0px auto 0 auto;
      margin-top: -100px;
    }
    .center {
      @media (min-width: 768px) {
        padding: 30px 150px;
      }
    }
    p {
      color: #000;
      padding: 10px;
    }
    h1 {
      text-align: center;
      color: #000;
      font-size: 26px;
      @media (min-width: 768px) {
        font-size: 32px;
      }
      @media (min-width: 1280px) {
        font-size: 62px;
      }
    }
    h2 {
      font-size: 22px;
      @media (min-width: 768px) {
        font-size: 28px;
      }
      @media (min-width: 1280px) {
        font-size: 34px;
      }
      text-align: center;
      em {
        color: pink;
      }
    }
    .nextButton,
    .previousButton {
      background: #000;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: grid;
      align-items: center;
      justify-items: center;
      svg {
        polygon {
          fill: #ffffff;
        }
      }
    }
  }
`

const Bg = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-color: ${({ background }) => background};
  text-align: center;
  ul {
    li {
      font-size: 18px;
      list-style-type: none;
    }
  }
`
const BgBlockPhoto = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center center;
  //background-color: ${({ background }) => background};
  img {
    text-align: center;
    display: block;
    margin: 0 auto;
    max-width: 50%;
  }
`

const GridPhotoInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1280px) {
    display: block;
  }
  grid-column-gap: 50px;
  p {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  ul {
    li {
      text-align: left;
      font-size: 18px;
    }
  }
  img {
    width: 100%;
  }
`

const CardQuote = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 120px !important;
  @media (max-width: 1280px) {
    display: block;
  }
  p {
    text-align: center;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
  img {
    border-radius: 50%;
    width: 500px;
    height: 500px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    @media (max-width: 1280px) {
      width: 250px;
      height: 250px;
    }
  }
  div {
    border-radius: 30px;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  h2 {
    text-align: center;
    font-style: italic;
  }
`

const Slide = () => {
  const [current, setCurrent] = useState(1)

  // const [theRest, theRest] = useState(false);
  const trans = x => `translateX(${x}px)`

  const [props, set] = useSpring(() => ({ x: 0 }))
  const [bgMoveRight, setBgMoveRight] = useState(false)
  const [bgMoveLeft, setBgMoveLeft] = useState(false)

  console.log(current)

  const next = (order, i) => {
    let nextSlide = order + 1
    setBgMoveRight(true)
    setTimeout(function() {
      setCurrent(nextSlide)
    }, 900)
    setTimeout(function() {
      setBgMoveRight(false)
    }, 1000)

    if (nextSlide > data.allSanitySlideDeck.edges.length) {
      nextSlide = 1
      setCurrent(nextSlide)
    }
  }
  const back = (order, i) => {
    let backSlide = order - 1
    setBgMoveLeft(true)
    setTimeout(function() {
      setCurrent(backSlide)
    }, 900)
    setTimeout(function() {
      setBgMoveLeft(false)
    }, 1000)

    if (backSlide < 1) {
      backSlide = data.allSanitySlideDeck.edges.length
      setTimeout(function() {
        setCurrent(backSlide)
      }, 900)
    }
  }

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  const data = useStaticQuery(graphql`
    query PresentationQuery {
      sanityPresentation {
        _rawCard(resolveReferences: { maxDepth: 10 })
      }
    }
  `)

  return (
    <>
      <SliderWrapper>
        <Slider touchDisabled={true}>
          {data.sanityPresentation._rawCard.map((slide, i) => (
            <div key="index">
              {slide._type === "cardInfo" && (
                <div className="center">
                  <Bg className="bg" key={i} background={slide.backgroundColor}>
                    <h1>{slide.title}</h1>
                    <BlockContent
                      blocks={slide.content}
                      serializers={serializers}
                    />
                  </Bg>
                </div>
              )}
              {slide._type === "cardPhoto" && (
                <div className="center">
                  <BgBlockPhoto
                    className="bg"
                    key={i}
                    background={slide.backgroundColor}
                  >
                    <h1>{slide.title}</h1>
                    <img src={slide?.image?.asset?.url} />
                  </BgBlockPhoto>
                </div>
              )}
              {slide._type === "cardPhotoInfo" && (
                <div className="center">
                  <Bg className="bg" key={i} background={slide.backgroundColor}>
                    <h1>{slide.title}</h1>
                    <GridPhotoInfo>
                      <BlockContent
                        blocks={slide.content}
                        serializers={serializers}
                      />
                      <img src={slide?.image?.asset?.url} />
                    </GridPhotoInfo>
                  </Bg>
                </div>
              )}
              {slide._type === "cardQuote" && (
                <CardQuote className="center">
                  <Bg className="bg" key={i} background={slide.backgroundColor}>
                    <p>{slide.quote}</p>
                    <h2>{slide.author}</h2>
                  </Bg>
                  <img src={slide?.authorImage?.asset?.url} />
                </CardQuote>
              )}
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </>
  )
}

export default Slide
