import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import Slide from "../components/slidePresent"

const ImageWrap = styled.div`
  .ri-trigger {
    width: 1800px;
    height: 600px;
    background-size: cover;
    background-position: 50% 50%;
    margin: 0 auto;
    display: block;
  }
`

const PanPage = ({ data }) => {
  return (
    <Layout>
      <Slide />
    </Layout>
  )
}

export default PanPage
